import React from 'react';
import { Container, Typography, Box, Grid, Card, Link, TextField, CardContent, Button, useMediaQuery } from '@mui/material';
import { ReactComponent as UnityLogo } from '../assets/images/unity-logo.svg';
import { ReactComponent as UnrealLogo } from '../assets/images/unreal-logo.svg';
import { ReactComponent as AWSLogo } from '../assets/images/aws-logo.svg';
import ServicesSection from '../components/ServicesSection';
import Banner from '../components/Banner';
import gameBanner from "../assets/images/home-banner-1.png";
import GameVideo from '../assets/images/game1.mp4';
import GamerPortalVideo from '../assets/images/gamerportal.mp4';
import VideoSection from '../components/VideoSection'; 
import { useFormik } from 'formik';
import * as Yup from 'yup';
import emailjs from 'emailjs-com';

const Game = () => {
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const validationSchema = Yup.object({
    email: Yup.string().email('Invalid email address').required('Email is required'),
    firstName: Yup.string().required('First Name is required'),
    lastName: Yup.string().required('Last Name is required'),
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      firstName: '',
      lastName: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values, { setSubmitting, resetForm }) => {
      emailjs.send('service_4747w2j', 'template_qerbe29', values, '24h2zM6cmq-PmhLP1')
        .then((response) => {
          console.log('SUCCESS!', response.status, response.text);
          alert('Subscription successful');
          resetForm();
        })
        .catch((error) => {
          console.log('FAILED...', error);
          alert('Failed to subscribe');
        })
        .finally(() => {
          setSubmitting(false);
        });
    },
  });

  const services = [
    {
      title: 'Custom Game Development',
      description: 'Crafting unique and immersive games tailored to your vision.',
      icon: '🎮',
    },
    {
      title: 'Cross-Platform Game Solutions',
      description: 'Developing games that run seamlessly on multiple platforms including PC, consoles, and mobile.',
      icon: '🔄',
    },
    {
      title: '3D Modeling & Animation',
      description: 'Creating stunning 3D models and animations to bring your game world to life.',
      icon: '🎨',
    },
    {
      title: 'AR/VR Game Development',
      description: 'Building cutting-edge augmented and virtual reality experiences.',
      icon: '🕶️',
    },
    {
      title: 'Game Testing & QA',
      description: 'Ensuring your game is bug-free and delivers a smooth player experience.',
      icon: '🔍',
    },
    {
      title: 'Live Operations & Updates',
      description: 'Providing continuous support and updates to keep your game fresh and engaging.',
      icon: '🚀',
    }
  ];

  return (
    <Container>
      {/* Header Section */}
      <Banner
        image={gameBanner}
        title="Unleashing Your Imagination with Custom Game Development"
        description="Join us in creating beautiful and engaging games that captivate players worldwide"
        isSmallScreen={isSmallScreen}
      />

      {/* Services Section */}
      <ServicesSection services={services} />

      {/* Game Development Process Section */}
      <Box sx={{ py: 8, backgroundColor: '#f0f0f0', padding:'40px !important' }}>
        <Typography className="font fontbold700 mb50" variant="h4" component="h2" gutterBottom>
          Our Game Development Process
        </Typography>
        <Typography className="font mb50" variant="body1" component="p" gutterBottom>
          Our game development process is designed to bring your vision to life, from initial concept to final release. Here’s a glimpse into our development journey:
        </Typography>
        <Grid container spacing={4}>
          {[
            { title: 'Concept & Design', description: 'We start with a strong concept and detailed design, focusing on creating engaging gameplay mechanics and compelling storylines.' },
            { title: 'Prototyping', description: 'We develop prototypes to test and refine game mechanics, ensuring a fun and immersive player experience.' },
            { title: 'Development', description: 'Our skilled developers bring your game to life using advanced technologies and best practices in game development.' },
            { title: 'Testing & QA', description: 'We rigorously test the game to identify and fix bugs, ensuring a smooth and enjoyable gameplay experience.' },
            { title: 'Launch & Live Operations', description: 'We handle the game’s launch and provide ongoing support and updates to keep players engaged.' }
          ].map((process, index) => (
            <Grid item xs={12} md={4} key={index}>
              <Card sx={{ height: '100%' }}>
                <CardContent>
                  <Typography className="font fontbold700" variant="h6" component="h3" gutterBottom>
                    {process.title}
                  </Typography>
                  <Typography className="font" variant="body1" component="p">
                    {process.description}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>

      {/* Technologies Section */}
      <Box sx={{ py: 8 }}>
        <Typography className="font" variant="h4" component="h2" gutterBottom>
          We work with future proof technologies like
        </Typography>
        <Grid container spacing={4} justifyContent="center">
          {[{ name: 'Unity', logo: <UnityLogo style={{ maxWidth: '100%', height: 'auto', maxHeight: '250px' }} /> }, { name: 'Unreal Engine', logo: <UnrealLogo style={{ maxWidth: '100%', height: 'auto', maxHeight: '250px' }} /> }, { name: 'AWS', logo: <AWSLogo style={{ maxWidth: '100%', height: 'auto', maxHeight: '250px' }} /> }].map((tech, index) => (
            <Grid item xs={12} md={4} key={index}>
              <Card sx={{ height: '100%' }}>
                <CardContent sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
                  {tech.logo}
                  <Typography variant="h6" component="p" mt={2}>
                    {tech.name}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
    
        <Typography className="font fontbold700" variant="h4" component="h2" gutterBottom>
            From Basic Games to Advanced VR and AR Applications – Bringing Your Vision to Life
        </Typography>
      <VideoSection
          videoSrc={GameVideo} 
          title=""
          description=""
          texts={[
          
          ]}
          interval={4000} 
        />

        

      {/* Why Choose Us Section */}
      <Box sx={{ py: 8 }}>
        <Typography variant="h4" component="h2" gutterBottom>
          Why Choose Us
        </Typography>
        <Grid container spacing={4}>
          {[
            { title: 'Experienced Team', description: 'Our team consists of highly skilled professionals with years of experience in game development.' },
            { title: 'Creative Vision', description: 'We bring a unique and creative approach to every project, ensuring your game stands out.' },
            { title: 'Agile Methodology', description: 'We follow Agile practices to ensure timely delivery and flexibility to adapt to changing requirements.' },
            { title: 'Customer Satisfaction', description: 'We prioritize our clients’ needs and work closely with them to achieve their business goals.' },
            { title: 'Innovative Solutions', description: 'We leverage cutting-edge technologies to deliver innovative and future-proof solutions.' },
            { title: 'Comprehensive Support', description: 'We provide end-to-end support, from initial consultation to post-deployment maintenance.' }
          ].map((item, index) => (
            <Grid item xs={12} md={4} key={index}>
              <Card sx={{ height: '100%' }}>
                <CardContent>
                  <Typography variant="h6" component="h3" gutterBottom>
                    {item.title}
                  </Typography>
                  <Typography variant="body1" component="p">
                    {item.description}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>


      <Typography className="font fontbold700" variant="h4" component="h2" gutterBottom>
            Inviting all the gamers world wide to join us and make this story a success that brings joy and wealth for all of our community
        </Typography>
        <VideoSection
          videoSrc={GamerPortalVideo} 
          title=""
          description=""
          texts={[
          
          ]}
          interval={4000} 
        />

    <Typography className="font fontbold700 mt50" variant="h4" component="h2" gutterBottom>
        Join Gaming Community
    </Typography>
    <Typography className="font" variant="body1" component="p" gutterBottom>
        Never miss regular updates and exciting news. Join our community newsletter!
    </Typography>
    <Box sx={{ py: 4, textAlign: 'center' }}>
    <form onSubmit={formik.handleSubmit} encType="multipart/form-data">
        <Grid container spacing={4} justifyContent="center">
            <Grid item xs={12} md={4}>
                <TextField
                    fullWidth
                    id="firstName"
                    name="firstName"
                    label="First Name"
                    value={formik.values.firstName}
                    onChange={formik.handleChange}
                    error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                    helperText={formik.touched.firstName && formik.errors.firstName}
                    sx={{ mb: 2 }}
                />
            </Grid>
            <Grid item xs={12} md={4}>
                <TextField
                    fullWidth
                    id="lastName"
                    name="lastName"
                    label="Last Name"
                    value={formik.values.lastName}
                    onChange={formik.handleChange}
                    error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                    helperText={formik.touched.lastName && formik.errors.lastName}
                    sx={{ mb: 2 }}
                />
            </Grid>
            <Grid item xs={12} md={4}>
                <TextField
                    fullWidth
                    id="email"
                    name="email"
                    label="Email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                    sx={{ mb: 2 }}
                />
            </Grid>
            <Grid item xs={12}>
                <Button className="Buttons font"
                    variant="contained"
                    color="primary"
                    fullWidth
                    type="submit"
                    disabled={formik.isSubmitting}
                >
                    Subscribe
                </Button>
            </Grid>
        </Grid>
    </form>
</Box>

      {/* Contact Us Section */}
      <Box sx={{ backgroundColor: '#f0f0f0', py: 8 }}>
        <Container>
          <Typography variant="h4" component="h2" gutterBottom textAlign="center">
            Ready to Start Your Project?
          </Typography>
          <Box textAlign="center">
             <Button className="Buttons font" variant="contained" color="primary" href="#contact">
                 <Link className="font" sx={{color:'white', textDecoration: 'none'}} href="mailto:info@supertracks.tech"> Contact Us</Link>
            </Button>
          </Box>
        </Container>
      </Box>
    </Container>
  );
};

export default Game;
