import React from 'react';
import { Container, Typography, Box, Link, Grid, Card, CardContent, Button, useMediaQuery } from '@mui/material';
import { ReactComponent as PythonLogo } from '../assets/images/python-logo.svg';
import { ReactComponent as NodeLogo } from '../assets/images/node-logo.svg';
import { ReactComponent as AWSLogo } from '../assets/images/aws-logo.svg';
import ServicesSection from '../components/ServicesSection';
import Banner from '../components/Banner';
import homeBanner1 from "../assets/images/home-banner-1.png";
import DockerImage from "../assets/images/docker.svg";
import ZeroTrust from "../assets/images/zero-trust.png";

const Web = () => {
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const services = [
    {
      title: 'Custom Web Development',
      description: 'Building tailored web applications that meet your specific business needs.',
      icon: '💻',
    },
    {
      title: 'E-commerce Solutions',
      description: 'Developing robust and scalable e-commerce platforms to boost your online sales.',
      icon: '🛒',
    },
    {
      title: 'Content Management Systems',
      description: 'Implementing user-friendly CMS solutions for easy content management.',
      icon: '📄',
    },
    {
      title: 'Web App Modernization',
      description: 'Upgrading and refactoring existing web applications for better performance and scalability.',
      icon: '🔧',
    },
    {
      title: 'Quality Assurance',
      description: 'Ensuring your web applications are bug-free and perform optimally across all devices.',
      icon: '🔍',
    },
    {
      title: 'DevOps Services',
      description: 'Automating your development processes to enhance efficiency and reduce time-to-market.',
      icon: '🚀',
    }
  ];

  return (
    <Container>
      {/* Header Section */}
      <Banner
            image={homeBanner1}
            title="Implementing scalable and robust web applications"
            description="Join with us in this journey and embark a new era of web development"
            isSmallScreen={isSmallScreen}
        />

      {/* Services Section */}
      <ServicesSection services={services} />

      {/* Containerization and Orchestration Section */}
      <Box sx={{ py: 8, backgroundColor: '#343434', color: 'white', padding: '40px', borderRadius: '10px', textAlign: 'center' }}>
      <Typography className="font fontbold700" variant="h4" component="h2" gutterBottom>
        Containerization and Orchestration
      </Typography>
      <Box sx={{ display: 'flex', justifyContent: 'center', py: 2 }}>
        <img src={DockerImage} alt="Docker" style={{ maxWidth: '100%', height: 'auto' }} />
      </Box>
      <Typography className="font" variant="body1" component="p" gutterBottom>
       We use Docker and Kubernetes together to enhance application deployment and management by encapsulating applications with their dependencies, ensuring consistency across environments. This helps us to streamline the creation, deployment, and running of applications, while Kubernetes automates deployment, scaling, and management. To secure communication between containers and microservices, we implement network policies and use service meshes like Istio for encrypted communication, manage secrets securely, ensure data encryption, enforce robust authentication and authorization, and continuously monitor and scan for vulnerabilities. This approach optimizes resource utilization, improves reliability, and ensures secure, scalable, and efficient application management
      </Typography>
    </Box>

      {/* Technologies Section */}
      <Box sx={{ py: 8 }}>
        <Typography className="font fontbold700" variant="h4" component="h2" gutterBottom>
          We work with future proof technologies like
        </Typography>
        <Grid className="font" container spacing={4} justifyContent="center">
         {[{ name: 'Python', logo: <PythonLogo style={{ maxWidth: '100%', height: 'auto', maxHeight: '250px' }} /> }, { name: 'Node.js', logo: <NodeLogo style={{ maxWidth: '100%', height: 'auto', maxHeight: '250px' }} /> }, { name: 'AWS', logo: <AWSLogo style={{ maxWidth: '100%', height: 'auto', maxHeight: '250px' }} /> }].map((tech, index) => (
            <Grid item xs={12} md={4} key={index}>
              <Card sx={{ height: '100%' }}>
                <CardContent sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
                  {tech.logo}
                  <Typography className="font" variant="h6" component="p" mt={2}>
                    {tech.name}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>


    <Box sx={{ py: 8, backgroundColor: '#343434', color: 'white', padding: '40px', borderRadius: '10px', textAlign: 'center' }}>
      <Typography className="font fontbold700" variant="h4" component="h2" gutterBottom>
        Zero Trust Security Model in Web Application Development
      </Typography>
      <Box sx={{ display: 'flex', justifyContent: 'center', py: 2 }}>
        <img src={ZeroTrust} alt="Docker" style={{ maxWidth: '100%', height: 'auto' }} />
      </Box>
      <Typography className="font" variant="body1" component="p" gutterBottom>
      The Zero Trust Security Model is a crucial concept in modern web application development, emphasizing that no entity, whether inside or outside the network, should be trusted by default. This model requires strict verification for every user and device attempting to access resources on a private network. Implementing Zero Trust involves several key practices: ensuring robust multi-factor authentication (MFA) to verify user identities, deploying least privilege access to limit user permissions to only what is necessary, continuously monitoring and logging user activity to detect and respond to anomalies, segmenting networks to contain potential breaches, and encrypting data both in transit and at rest to protect sensitive information. Adopting a Zero Trust approach significantly enhances security by reducing the attack surface and mitigating risks associated with traditional perimeter-based security models, thereby ensuring a more resilient and secure web application environment.
      </Typography>
    </Box>

      {/* Case Studies Section */}
      <Box sx={{ py: 8, backgroundColor: '#343434', marginTop: '50px', padding: '50px' }}>
        <Typography className="font" sx={{color: 'white !important', marginBottom: '40px !important'}} variant="h4" component="h2" gutterBottom>
          Case Studies
        </Typography>
        <Grid container spacing={4}>
          {[
            { client: 'Creeno', project: 'Warehouse Management Portal', implementation: 'Creeno adopted the Zero Trust Security Model to ensure that no user or device is trusted by default. This approach was crucial for securing internal communications and protecting against potential threats. The system was integrated with continuous monitoring tools to log and analyze user activities, enabling quick detection and response to suspicious behavior', outcome: 'Handles 10,000 transactions per day, streamlining operations and increasing efficiency.',  },
            { client: 'Proprenz Biotech', project: 'Large Warehouse Management Portal', implementation: 'The network was segmented to isolate critical systems and data, minimizing the potential impact of a security breach. Comprehensive encryption protocols were applied to secure data at rest and in transit, ensuring that sensitive information remained protected from cyber threats.', outcome: 'Efficiently manages high transaction volumes and ensures seamless operations.' }
          ].map((caseStudy, index) => (
            <Grid item xs={12} md={6} key={index}>
              <Card sx={{ height: '100%' }}>
                <CardContent>
                  <Typography className="font fontbold700" variant="h6" component="h3" gutterBottom>
                    {caseStudy.client}
                  </Typography>
                  <Typography className="font"  variant="body1" component="p">
                    <p className="font fontbold700"> Project: </p> {caseStudy.project}
                  </Typography>
                  <Typography className="font"   variant="body1" component="p">
                   <p className="font fontbold700"> Implementation: </p>{caseStudy.implementation}
                  </Typography>
                  <Typography className="font"   variant="body1" component="p">
                  <p className="font fontbold700"> Outcome: </p> {caseStudy.outcome}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>

      {/* Why Choose Us Section */}
      <Box sx={{ py: 8 }}>
        <Typography className="font fontbold700" variant="h4" component="h2" gutterBottom>
          Why Choose Us
        </Typography>
        <Grid className="font" container spacing={4}>
          {[
            { title: 'Experienced Team', description: 'Our team consists of highly skilled professionals with years of experience in web development.' },
            { title: 'Agile Methodology', description: 'We follow Agile practices to ensure timely delivery and flexibility to adapt to changing requirements.' },
            { title: 'Customer Satisfaction', description: 'We prioritize our clients’ needs and work closely with them to achieve their business goals.' },
            { title: 'Innovative Solutions', description: 'We leverage cutting-edge technologies to deliver innovative and future-proof solutions.' },
            { title: 'Comprehensive Support', description: 'We provide end-to-end support, from initial consultation to post-deployment maintenance.' }
          ].map((item, index) => (
            <Grid item xs={12} md={4} key={index}>
              <Card sx={{ height: '100%' }}>
                <CardContent>
                  <Typography className="font fontbold700"  variant="h6" component="h3" gutterBottom>
                    {item.title}
                  </Typography>
                  <Typography className="font"  variant="body1" component="p">
                    {item.description}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>

      {/* Contact Us Section */}
      <Box sx={{ backgroundColor: '#f0f0f0', py: 8 }}>
        <Container>
          <Typography className="fontbold700"  variant="h4" component="h2" gutterBottom textAlign="center">
            Ready to Start Your Project?
          </Typography>
          <Box textAlign="center">
            <Button className="Buttons font" variant="contained" color="primary" href="#contact">
                 <Link className="font" sx={{color:'white', textDecoration: 'none'}} href="mailto:info@supertracks.tech"> Contact Us</Link>
            </Button>
          </Box>
        </Container>
      </Box>
    </Container>
  );
};

export default Web;
