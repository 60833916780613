import React, { useState, useEffect } from 'react';
import { Box, Typography, useMediaQuery } from '@mui/material';
import "./componentstyles.css";

const VideoSection = ({ title, description, videoSrc, texts, interval }) => {
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const [currentTextIndex, setCurrentTextIndex] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTextIndex((prevIndex) => (prevIndex + 1) % texts.length);
    }, interval);

    return () => clearInterval(timer);
  }, [texts, interval]);

  return (
    <Box className="mt100" sx={{ my: 4, textAlign: 'center', position: 'relative' }}>
      <Typography className="mt100 font font-size30" component="h1" gutterBottom>
        {title}
      </Typography>
      <Typography className="mt50 font font-size16" variant="body1" component="p" gutterBottom>
        {description}
      </Typography>
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative' }}>
        <video 
          autoPlay
          controls
          style={{
            width: isSmallScreen ? '100%' : '80%',
            maxWidth: '1400px',
            borderRadius: '10px',
            marginTop: '50px',
          }}
        >
          <source src={videoSrc} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            color: 'white',
            padding: '10px',
            borderRadius: '10px',
            fontFamily: 'Ubuntu',
          }}
        >
          <Typography className="font responsive-text" variant="h6" component="p">
            {texts[currentTextIndex]}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default VideoSection;
