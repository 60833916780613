import React from 'react';
import { Box, Typography, useMediaQuery } from '@mui/material';
import "./componentstyles.css";

const TextImageSection = ({ title, text, image }) => {
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  return (
    <Box className="mt100" sx={{ my: 4, display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center', flexDirection: isSmallScreen ? 'column' : 'row' }}>
      <Box sx={{ flex: 1, p: 2 }}>
        <Typography className="font" variant="h4" component="h2" gutterBottom>
          {title}
        </Typography>
        <Typography className="font" variant="body1" component="p" gutterBottom>
          {text}
        </Typography>
      </Box>
      <Box sx={{ flex: 1, p: 2 }}>
        <img src={image} alt={title} style={{ width: '100%', maxWidth: '400px', height: 'auto' }} />
      </Box>
    </Box>
  );
};

export default TextImageSection;
