import React from 'react';
import { Container, Typography, Box, Grid, Card, Link,  CardContent, Button, useMediaQuery } from '@mui/material';
import { ReactComponent as ReactLogo } from '../assets/images/python-logo.svg';
import { ReactComponent as NodeLogo } from '../assets/images/java-logo.svg';
import { ReactComponent as AWSLogo } from '../assets/images/aws-logo.svg';
import ServicesSection from '../components/ServicesSection';
import Banner from '../components/Banner';
import securityBanner from "../assets/images/home-banner-1.png";
import SecurityProductVideo from '../assets/images/securityproduct.mp4';
import VideoSection from '../components/VideoSection'; 
import securityImage from "../assets/images/securityImage.svg";
import TextImageSection from '../components/TextImageSection';

const Product = () => {
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const services = [
    {
      title: 'Custom Security Solutions',
      description: 'Designing and developing tailored security solutions to meet your specific organizational needs.',
      icon: '🔒',
    },
    {
      title: 'Risk Assessment & Management',
      description: 'Comprehensive risk assessment and management services to identify and mitigate potential threats.',
      icon: '⚠️',
    },
    {
      title: 'Compliance & Governance',
      description: 'Ensuring your security solutions comply with industry standards and regulations.',
      icon: '📜',
    },
    {
      title: 'Security Training & Awareness',
      description: 'Providing training and awareness programs to educate your staff on best security practices.',
      icon: '🎓',
    },
    {
      title: 'Incident Response',
      description: 'Developing and implementing effective incident response strategies to minimize the impact of security breaches.',
      icon: '🚨',
    },
    {
      title: 'Continuous Monitoring & Support',
      description: 'Offering ongoing monitoring and support to ensure your security systems remain effective.',
      icon: '🔍',
    }
  ];

  return (
    <Container>
      {/* Header Section */}
      <Banner
        image={securityBanner}
        title="Revolutionizing Security with Our SmartSecurity Concept"
        description="Join us in transforming the way organizations manage cybersecurity, cutting costs by up to 70% with our tailored solutions."
        isSmallScreen={isSmallScreen}
      />

      {/* Services Section */}
      <ServicesSection services={services} />

      {/* SmartSecurity Development Process Section */}
      <Box sx={{ py: 8, backgroundColor: '#f0f0f0', padding:'40px', borderRadius: '10px' }}>
        <Typography className="font fontbold700" variant="h4" component="h2" gutterBottom>
          Our SmartSecurity Development Process
        </Typography>
        <Typography className="font" variant="body1" component="p" gutterBottom>
          Our SmartSecurity concept is designed to provide robust and cost-effective security solutions tailored to your organizational needs. Here’s a detailed look at our development process:
        </Typography>
        <Grid container spacing={4}>
          {[
            { title: 'Initial Consultation', description: 'We begin with a thorough consultation to understand your security needs and challenges.' },
            { title: 'Custom Solution Design', description: 'Based on the consultation, we design a custom security solution that addresses your specific requirements.' },
            { title: 'Implementation & Integration', description: 'We implement and integrate the security solution into your existing infrastructure, ensuring minimal disruption.' },
            { title: 'Testing & Validation', description: 'We conduct rigorous testing to ensure the solution is effective and meets all security standards.' },
            { title: 'Training & Deployment', description: 'We provide comprehensive training to your staff and deploy the solution, ensuring it is fully operational.' },
            { title: 'Ongoing Support & Optimization', description: 'We offer continuous support and optimization to keep your security systems up-to-date and effective.' }
          ].map((process, index) => (
            <Grid item xs={12} md={4} key={index}>
              <Card sx={{ height: '100%' }}>
                <CardContent>
                  <Typography className="font fontbold700" variant="h6" component="h3" gutterBottom>
                    {process.title}
                  </Typography>
                  <Typography className="font" variant="body1" component="p">
                    {process.description}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>

      {/* Technologies Section */}
      <Box sx={{ py: 8 }}>
        <Typography className="font fontbold700" variant="h4" component="h2" gutterBottom>
          Technologies We Use
        </Typography>
        <Grid container spacing={4} justifyContent="center">
          {[{ name: 'Python', logo: <ReactLogo style={{ maxWidth: '100%', height: 'auto', maxHeight: '250px' }} /> }, { name: 'Java', logo: <NodeLogo style={{ maxWidth: '100%', height: 'auto', maxHeight: '250px' }} /> }, { name: 'AWS', logo: <AWSLogo style={{ maxWidth: '100%', height: 'auto', maxHeight: '250px' }} /> }].map((tech, index) => (
            <Grid item xs={12} md={4} key={index}>
              <Card sx={{ height: '100%' }}>
                <CardContent sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
                  {tech.logo}
                  <Typography className="font" variant="h6" component="p" mt={2}>
                    {tech.name}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>

      <Typography className="font fontbold700" variant="h4" component="h2" gutterBottom>
        Cost Savings Through In-House Development - observe significant reductions in your cybersecurity spendings.
        </Typography>
        <VideoSection
          videoSrc={SecurityProductVideo} 
          title=""
          description=""
          texts={[
          
          ]}
          interval={4000} 
        />

        <TextImageSection
          title="Implementation Strategy"
          text="Begining with a detailed consultation to understand the client's specific security needs and challenges. Implement and integrate the solution into the client's existing infrastructure with minimal disruption."
          image={securityImage}
        />
        
      {/* Why Choose Us Section */}
      <Box sx={{ py: 8 }}>
        <Typography className="font fontbold700" variant="h4" component="h2" gutterBottom>
          Why Choose Us
        </Typography>
        <Grid container spacing={4}>
          {[
            { title: 'Experienced Team', description: 'Our team consists of highly skilled professionals with years of experience in security product development.' },
            { title: 'Custom Solutions', description: 'We design and develop solutions tailored to your specific needs, ensuring maximum efficiency and cost savings.' },
            { title: 'Agile Methodology', description: 'We follow Agile practices to ensure timely delivery and flexibility to adapt to changing requirements.' },
            { title: 'Customer Satisfaction', description: 'We prioritize our clients’ needs and work closely with them to achieve their business goals.' },
            { title: 'Innovative Technologies', description: 'We leverage cutting-edge technologies to deliver innovative and future-proof security solutions.' },
            { title: 'Comprehensive Support', description: 'We provide end-to-end support, from initial consultation to post-deployment maintenance.' }
          ].map((item, index) => (
            <Grid item xs={12} md={4} key={index}>
              <Card sx={{ height: '100%' }}>
                <CardContent>
                  <Typography className="font fontbold700" variant="h6" component="h3" gutterBottom>
                    {item.title}
                  </Typography>
                  <Typography className="font" variant="body1" component="p">
                    {item.description}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>

      {/* Contact Us Section */}
      <Box sx={{ backgroundColor: '#f0f0f0', py: 8 }}>
        <Container>
          <Typography className="font" variant="h4" component="h2" gutterBottom textAlign="center">
            Ready to Start Your Project?
          </Typography>
          <Box textAlign="center">
            <Button className="Buttons font" variant="contained" color="primary" href="#contact">
                 <Link className="font" sx={{color:'white', textDecoration: 'none'}} href="mailto:info@supertracks.tech"> Contact Us</Link>
            </Button>
          </Box>
        </Container>
      </Box>
    </Container>
  );
};

export default Product;
