import React from 'react';
import { Container, Typography, Box, Grid, Card, CardContent, Link, Button, useMediaQuery } from '@mui/material';
import { ReactComponent as JavaLogo } from '../assets/images/java-logo.svg';
import { ReactComponent as NodeLogo } from '../assets/images/node-logo.svg';
import { ReactComponent as AWSLogo } from '../assets/images/aws-logo.svg';
import ServicesSection from '../components/ServicesSection';
import Banner from '../components/Banner';
import homeBanner1 from "../assets/images/home-banner-1.png";
import VideoSection from '../components/VideoSection'; 
import MobileVideo from '../assets/images/mobileVideo.mp4';
import MobileCycle from "../assets/images/mobileCycle.png";


const Mobile = () => {
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  
  const services = [
    {
      title: 'Custom Mobile App Development',
      description: 'Creating bespoke mobile applications tailored to your business needs.',
      icon: '📱',
    },
    {
      title: 'Cross-Platform Solutions',
      description: 'Developing apps that run seamlessly on both iOS and Android platforms.',
      icon: '🔄',
    },
    {
      title: 'UI/UX Design',
      description: 'Designing intuitive and engaging user interfaces for mobile applications.',
      icon: '🎨',
    },
    {
      title: 'App Modernization',
      description: 'Updating and refactoring existing apps for enhanced performance and usability.',
      icon: '🔧',
    },
    {
      title: 'Quality Assurance',
      description: 'Ensuring your mobile applications are bug-free and perform optimally across all devices.',
      icon: '🔍',
    },
    {
      title: 'DevOps Services',
      description: 'Automating your mobile app development processes to enhance efficiency and reduce time-to-market.',
      icon: '🚀',
    }
  ];

  return (
    <Container>
      {/* Header Section */}
      <Banner
        image={homeBanner1}
        title="Empowering Your Vision with Bespoke Mobile App Creations"
        description="Join us in this journey to create innovative and user-friendly mobile applications"
        isSmallScreen={isSmallScreen}
      />

      {/* Services Section */}
      <ServicesSection services={services} />

      {/* Mobile Technologies Section */}
      <Box sx={{ py: 8, backgroundColor: '#f0f0f0', marginBottom: '50px', padding: '40px' }}>
        <Typography className="font fontbold700 mb50" variant="h4" component="h2" gutterBottom>
          We work with future proof technologies like
        </Typography>
        <Grid container spacing={4} justifyContent="center">
          {[{ name: 'Java', logo: <JavaLogo style={{ maxWidth: '100%', height: 'auto', maxHeight: '250px' }} /> }, { name: 'Node.js', logo: <NodeLogo style={{ maxWidth: '100%', height: 'auto', maxHeight: '250px' }} /> }, { name: 'AWS', logo: <AWSLogo style={{ maxWidth: '100%', height: 'auto', maxHeight: '250px' }} /> }].map((tech, index) => (
            <Grid item xs={12} md={4} key={index}>
              <Card sx={{ height: '100%' }}>
                <CardContent sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
                  {tech.logo}
                  <Typography variant="h6" component="p" mt={2}>
                    {tech.name}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>

      <Typography className="font fontbold700 mb50" variant="h4" component="h2" gutterBottom>
            "Intuitive designs for user-friendly experience"
        </Typography>
      <VideoSection
          videoSrc={MobileVideo} 
          title=""
          description=""
          texts={[
          
          ]}
          interval={4000} 
        />

    <Box sx={{ py: 8, padding: '40px', borderRadius: '10px', textAlign: 'center' }}>
      <Box sx={{ display: 'flex', justifyContent: 'center', py: 2 }}>
        <img src={MobileCycle} alt="Docker" style={{ maxWidth: '100%', height: 'auto' }} />
      </Box>
    </Box>

      {/* Why Choose Us Section */}
      <Box sx={{ py: 8 }}>
        <Typography className="font fontbold700" variant="h4" component="h2" gutterBottom>
          Why Choose Us
        </Typography>
        <Grid container spacing={4}>
          {[
            { title: 'Experienced Team', description: 'Our team consists of highly skilled professionals with years of experience in mobile app development.' },
            { title: 'Agile Methodology', description: 'We follow Agile practices to ensure timely delivery and flexibility to adapt to changing requirements.' },
            { title: 'Customer Satisfaction', description: 'We prioritize our clients’ needs and work closely with them to achieve their business goals.' },
            { title: 'Innovative Solutions', description: 'We leverage cutting-edge technologies to deliver innovative and future-proof solutions.' },
            { title: 'Comprehensive Support', description: 'We provide end-to-end support, from initial consultation to post-deployment maintenance.' }
          ].map((item, index) => (
            <Grid item xs={12} md={4} key={index}>
              <Card sx={{ height: '100%' }}>
                <CardContent>
                  <Typography className="font fontbold700" variant="h6" component="h3" gutterBottom>
                    {item.title}
                  </Typography>
                  <Typography className="font" variant="body1" component="p">
                    {item.description}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>

      {/* Contact Us Section */}
      <Box sx={{ backgroundColor: '#f0f0f0', py: 8 }}>
        <Container>
          <Typography className="font" variant="h4" component="h2" gutterBottom textAlign="center">
            Ready to Start Your Project?
          </Typography>
          <Box textAlign="center">
          <Button className="Buttons font" variant="contained" color="primary" href="#contact">
                 <Link className="font" sx={{color:'white', textDecoration: 'none'}} href="mailto:info@supertracks.tech"> Contact Us</Link>
            </Button>
          </Box>
        </Container>
      </Box>
    </Container>
  );
};

export default Mobile;
