import React from 'react';
import { Container, Box, Typography, Link, Grid, IconButton } from '@mui/material';
import { Facebook, LinkedIn, Twitter, GitHub, Instagram } from '@mui/icons-material';
import MainLogo from "../assets/images/logotransparent.png";

const Footer = () => {
  return (
    <Box sx= {{ backgroundColor: '#000000e0', color: 'white', py: 4 }}>
      <Container>
        <Grid container spacing={4}>
          {/* Logo and Contact */}
          <Grid item xs={12} md={4}>
            <Typography variant="h6" component="div" gutterBottom>
              <img src={MainLogo} alt="Company Logo" style={{ width: '150px' }} />
            </Typography>
            <Box sx={{ backgroundColor: 'white', borderRadius:'10px', display: 'flex', alignItems: 'center', mt: 2 }}>

              <IconButton component="a" href="https://linkedin.com/company/supertracks" target="_blank">
                <LinkedIn />
              </IconButton>
              <IconButton component="a" href="https://github.com/Supertracks" target="_blank">
                <GitHub />
              </IconButton>
            </Box>
            <Typography variant="body2" color="white" component="p" mt={2}>
              Drop us a line: <Link href="mailto:info@supertracks.tech">info@supertracks.tech</Link>
            </Typography>
          </Grid>

          {/* Services */}
          <Grid item xs={12} md={4}>
            <Typography className="font" variant="h6" component="div" gutterBottom>
              Services
            </Typography>
            <Grid container spacing={1}>
              <Grid className="font"  item xs={6}>
                <Link href="/web" color="inherit">Web Development</Link><br />
                <Link href="/mobile" color="inherit">Mobile Development</Link><br />
                <Link href="product" color="inherit">Product Development</Link><br />
                <Link href="/game" color="inherit">Game Development</Link><br />
              </Grid>
            </Grid>
          </Grid>

          {/* Knowledge */}
          <Grid className="font" item xs={12} md={4}>
            <Typography className="font" variant="h6" component="div" gutterBottom>
              Knowledge
            </Typography>
            <Typography sx={{fontSize: '14px'}} className="font" component="div" gutterBottom>
              NOTE: Resources below will be vailable to download from no later than October 15th 2024.(We apologise for any inconvenience caused)
            </Typography>
            <Link href="#" color="inherit">Our Technology Guidebook</Link><br />
            <Link href="#" color="inherit">State of Microservices</Link><br />
            <Link href="#" color="inherit">State of Frontend & Backend</Link>
          </Grid>
        </Grid>

        {/* Addresses */}
        <Grid className="font" container spacing={4} mt={4}>
          <Grid item xs={12} md={4}>
            <Typography className="font"  variant="h6" component="div" gutterBottom>
              Headquarters
            </Typography>
            <Typography className="font"  variant="body2" color="white" component="p">
              129 Samatha Nagar<br />
              Hyderabad, 500085<br />
              India
            </Typography>
          </Grid>
          <Grid className="font"  item xs={12} md={4}>
            <Typography className="font"  variant="h6" component="div" gutterBottom>
              Business Development
            </Typography>
            <Typography className="font"  variant="body2" color="white" component="p">
              313 Fremont Ave<br />
              Michigan, 49412<br />
              USA
            </Typography>
          </Grid>
        </Grid>

        {/* Bottom Logos
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 4 }}>
          <img src="award1.png" alt="Award 1" style={{ width: '80px', marginRight: '20px' }} />
          <img src="award2.png" alt="Award 2" style={{ width: '80px', marginRight: '20px' }} />
          <img src="award3.png" alt="Award 3" style={{ width: '80px', marginRight: '20px' }} />
          <img src="award4.png" alt="Award 4" style={{ width: '80px', marginRight: '20px' }} />
          <img src="award5.png" alt="Award 5" style={{ width: '80px', marginRight: '20px' }} />
        </Box> */}
      </Container>
    </Box>
  );
};

export default Footer;
