import React from 'react';
import { Container, Typography, Box, Button, useMediaQuery } from '@mui/material';
import Carousel from 'react-material-ui-carousel';
import slider1 from '../assets/images/Slider1.png';
import "./pagestyles.css";
import slider2 from '../assets/images/Slider2.png';
import slider3 from '../assets/images/Slider3.png';
import slider4 from '../assets/images/Slider4.png';
import home1 from '../assets/images/web-app-1.png';
import home2 from '../assets/images/home-security.png';
import homeBanner1 from "../assets/images/home-banner-1.png";
import introductionVideo from '../assets/images/home-video-gaming.mp4';
import TextImageSection from '../components/TextImageSection'; 
import VideoSection from '../components/VideoSection'; 
import Banner from "../components/Banner";

const items = [
    {
      image: slider1,
      title: 'Web Applications Development',
      description: 'Crafting Tailored Web Solutions: Secure, Scalable, and Budget-Friendly Excellence',
      buttonText: 'Learn More',
      buttonLink: '/web'
    },
    {
      image: slider2,
      title: 'Mobile Applications Development',
      description: 'Empowering Your Vision with Bespoke Mobile App Creations',
      buttonText: 'Learn More',
      buttonLink: '/mobile'
    },
    {
      image: slider3,
      title: 'Gaming Applications',
      description: 'Unleashing Your Imagination with Custom Gaming App Creations',
      buttonText: 'Learn More',
      buttonLink: '/game'
    },
    {
      image: slider4,
      title: 'Smart Security',
      description: 'Tailored Security Solutions: Protect Your Business and Cut Cybersecurity Costs by Up to 70% with our innovative smart security concept',
      buttonText: 'Learn more',
      buttonLink: '/product'
    }
  ];
  
  const Home = () => {
    const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  
    return (
      <Container>
        <Box sx={{ my: 4 }}>
          <Carousel autoPlay={true} interval={3000}>
            {items.map((item, index) => (
              <Box
                key={index}
                sx={{
                  backgroundImage: `url(${item.image})`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  height: '800px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  color: 'white',
                  fontFamily: "Ubuntu",
                  textShadow: '0px 0px 10px rgba(0,0,0,0.5)',
                  borderRadius: '20px',
                }}
              >
                <Box sx={{ textAlign: 'center', p: 3, fontFamily: 'Ubuntu' }}>
                  <Typography className="font" variant="h3" component="h1" gutterBottom>
                    {item.title}
                  </Typography>
                  <Typography className="font" variant="h5" component="h2" gutterBottom>
                    {item.description}
                  </Typography>
                  <Button className="Buttons font" variant="contained" color="primary" href={item.buttonLink}>
                    {item.buttonText}
                  </Button>
                </Box>
              </Box>
            ))}
          </Carousel>
        </Box>
        
        <TextImageSection
          title="Unmatched Expertise in Web Application Development"
          text="At the core of our operations lies a team of dedicated professionals with a wealth of experience in web application development. Our journey has been marked by relentless pursuit of excellence and innovation, ensuring that every project we undertake is a testament to our expertise and commitment to quality."
          image={home1}
        />
        
        <Banner
            image={homeBanner1}
            title="Experience the new wave of AI powered web development with supertracks"
            description="Join with us in this journey and embark a new era of web development"
            buttonText="Explore more"
            buttonLink="/webapplications"
            isSmallScreen={isSmallScreen}
        />
        
        <TextImageSection
          title="Fortified Security and Unwavering Reliability"
          text="In today's digital age, the security of your data and the reliability of your web applications are paramount. We understand the critical importance of these aspects, which is why we have made them the cornerstone of our services. We go beyond basic security practices to implement robust measures that fortify your web applications. This includes secure coding practices, regular security audits, and vulnerability assessments. By integrating these practices into our development process, we build web applications that are resilient against cyber attacks."
          image={home2}
        />
        
        <VideoSection
          title="Supercharge Your Game Development"
          description="Learn more about our services developing a gaming application swiftly and efficiently can set you apart"
          videoSrc={introductionVideo} 
          texts={[
            'At Supertracks we prepare for growth',
            'We always aim to deliver scalable solutions',
            'Delivering tailor-made gaming solutions',
            'Choosing a development partner is a critical decision. We invite you to join our family of satisfied clients who have experienced the supertracks difference. Let’s create something extraordinary together.'
          ]}
          interval={4000} 
        />
      </Container>
    );
  };
  
  export default Home;
