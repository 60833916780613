import React, { useState } from 'react';
import { AppBar, Toolbar, Typography, Button, IconButton, Menu, MenuItem, Drawer, List, ListItem, ListItemText } from '@mui/material';
import { Link } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import "./componentstyles.css";
import MainLogo from "../assets/images/logotransparent.png";

const Navbar = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerOpen(open);
  };

  const links = [
    { label: 'Home', path: '/' },
    { label: 'Web Development', path: '/web' },
    { label: 'Mobile Development', path: '/mobile'},
    { label: 'Game Development', path: '/game' },
    { label: 'Product Development', path: '/product' },
    { label: 'Careers', path: '/careers' },
  ];

  const menuItems = links.map((link) => (
    <MenuItem component={Link} to={link.path} key={link.label} onClick={handleMenuClose}>
      {link.label}
    </MenuItem>
  ));

  const drawerItems = (
    <List className="font">
      {links.map((link) => (
        <ListItem button component={Link} to={link.path} key={link.label} onClick={toggleDrawer(false)}>
          <ListItemText primary={link.label} />
        </ListItem>
      ))}
    </List>
  );

  return (
    <AppBar position="static">
      <Toolbar className="toolbar-navbar">
        <Typography variant="h6" sx={{ flexGrow: 1 }}>
          <img className="MainLogo" src={MainLogo} alt="Supertracks - A technology company" />
        </Typography>
        <div className="navbar-links font900">
          {links.map((link) => (
            <Button className="font font500" color="inherit" component={Link} to={link.path} key={link.label} sx={{ display: { xs: 'none', md: 'inline-flex' } }}>
              {link.label}
            </Button>
          ))}
        </div>
        <IconButton
          edge="end"
          color="inherit"
          aria-label="menu"
          onClick={toggleDrawer(true)}
          sx={{ display: { xs: 'inline-flex', md: 'none' } }}
        >
          <MenuIcon />
        </IconButton>
        <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer(false)}>
          {drawerItems}
        </Drawer>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
