import React from 'react';
import { Box, Typography, Grid, Card, CardContent } from '@mui/material';

const ServicesSection = ({ services }) => {
  return (
    <Box sx={{ py: 8 }}>
      <Grid className="font"  container spacing={4}>
        {services.map((service, index) => (
          <Grid className="font"  item xs={12} md={4} key={index}>
            <Card sx={{ height: '100%' }}>
              <CardContent sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
                <Typography sx={{ fontSize: '2rem', mb: 2 }}>{service.icon}</Typography>
                <Typography className="font fontbold700"  variant="h6" component="h3" gutterBottom>
                  {service.title}
                </Typography>
                <Typography className="font"  variant="body1" component="p">
                  {service.description}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default ServicesSection;
