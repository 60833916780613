import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar';
import Home from './pages/Home';
import Web from "./pages/Web";
import Mobile from "./pages/Mobile";
import Footer from './components/Footer';
import Game from "./pages/Game";
import Product from './pages/Product';
import Careers from "./pages/Careers";
  

function App() {
  return (
    <Router>
      <div>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/web" element={<Web />} />
          <Route path="/mobile" element={<Mobile />} />
          <Route path="/game" element={<Game />} />
          <Route path="/product" element={<Product />} />
          <Route path="/careers" element={<Careers />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
