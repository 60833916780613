import React, { useState } from 'react';
import { Container, Typography, Box, Grid, Card, CardContent, Button, TextField, MenuItem, InputAdornment, IconButton } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import emailjs from 'emailjs-com';

const jobListings = {
  India: [
    { title: 'Project Manager', location: 'Mumbai, India', description: 'Oversee project timelines, deliverables, and team coordination.' },
    { title: 'Software Engineer 3', location: 'Bangalore, India', description: 'Develop and maintain web applications using modern frameworks and technologies.' },
    { title: 'Software Engineer 3', location: 'Delhi, India', description: 'Develop and maintain web applications using modern frameworks and technologies.' },
    { title: 'Software Engineer 3', location: 'Chennai, India', description: 'Develop and maintain web applications using modern frameworks and technologies.' },
    { title: 'Software Engineer 3', location: 'Hyderabad, India', description: 'Develop and maintain web applications using modern frameworks and technologies.' }
  ],
  USA: [
    { title: 'Software Architect', location: 'San Francisco, USA', description: 'Design and oversee the development of complex software systems.' },
    { title: 'Game Designer', location: 'Los Angeles, USA', description: 'Create and design engaging game mechanics and storylines.' },
    { title: 'Senior Game Developer', location: 'Austin, USA', description: 'Lead the development of high-quality games for various platforms.' },
    { title: 'Senior Software Engineer', location: 'New York, USA', description: 'Develop and maintain advanced software applications.' },
    { title: 'Senior Project Manager', location: 'Seattle, USA', description: 'Manage large-scale projects and ensure successful delivery.' },
    { title: 'Software Engineer 3', location: 'Chicago, USA', description: 'Develop and maintain web applications using modern frameworks and technologies.' },
    { title: 'Software Engineer 3', location: 'Boston, USA', description: 'Develop and maintain web applications using modern frameworks and technologies.' },
    { title: 'Software Engineer 3', location: 'Dallas, USA', description: 'Develop and maintain web applications using modern frameworks and technologies.' },
    { title: 'Software Engineer 3', location: 'Miami, USA', description: 'Develop and maintain web applications using modern frameworks and technologies.' }
  ]
};

const jobDescriptions = {
  'Project Manager': `**Position Title:** Project Manager

**Location:** Mumbai, India

**Job Type:** [Full-Time/Part-Time/Contract]

**About Us:**
Supertracks is a leading technology company dedicated to providing innovative and high-quality solutions to our clients. We are looking for an experienced Project Manager to join our dynamic team.

**Key Responsibilities:**
- Oversee project timelines, deliverables, and team coordination.
- Manage project scope, budget, and resources.
- Communicate project status to stakeholders.
- Identify and mitigate project risks.
- Ensure projects are delivered on time and within scope.

**Qualifications:**
- Bachelor’s degree in Business, Engineering, or related field.
- Minimum of 5 years of experience in project management.
- PMP or equivalent certification is a plus.
- Strong leadership and communication skills.
- Experience with project management software (e.g., MS Project, Asana).
`,

  'Software Engineer 3': `**Position Title:** Software Engineer 3

**Location:** [Bangalore, Delhi, Chennai, Hyderabad, India / Chicago, Boston, Dallas, Miami, USA]

**Job Type:** [Full-Time/Part-Time/Contract]

**About Us:**
Supertracks is a leading technology company dedicated to providing innovative and high-quality solutions to our clients. We are looking for an experienced Project Manager to join our dynamic team.

**Key Responsibilities:**
- Develop and maintain web applications using modern frameworks and technologies.
- Write clean, scalable, and efficient code.
- Participate in code reviews and provide feedback.
- Collaborate with cross-functional teams to define and design new features.
- Troubleshoot and debug applications.

**Qualifications:**
- Bachelor’s degree in Computer Science, Software Engineering, or related field.
- Minimum of 3 years of experience in software development.
- Proficiency in programming languages such as Java, Python, or C#.
- Experience with web development frameworks (e.g., React, Angular, Vue).
- Familiarity with version control systems (e.g., Git).
`,

  'Software Architect': `**Position Title:** Software Architect

**Location:** San Francisco, USA

**Job Type:** [Full-Time/Part-Time/Contract]

**About Us:**
Supertracks is a leading technology company dedicated to providing innovative and high-quality solutions to our clients. We are looking for an experienced Project Manager to join our dynamic team.

**Key Responsibilities:**
- Design and oversee the development of complex software systems.
- Provide technical guidance and mentorship to development teams.
- Collaborate with stakeholders to gather and analyze requirements.
- Identify and address performance bottlenecks.
- Create comprehensive documentation for architectural designs.

**Qualifications:**
- Bachelor’s or Master’s degree in Computer Science, Software Engineering, or related field.
- Minimum of 5 years of experience in software development and architecture.
- Proficiency in multiple programming languages (e.g., Java, Python, C#).
- Strong understanding of software architecture patterns and principles.
- Experience with cloud platforms (e.g., AWS, Azure, Google Cloud).
`,

  'Game Designer': `**Position Title:** Game Designer

**Location:** Los Angeles, USA

**Job Type:** [Full-Time/Part-Time/Contract]

**About Us:**
Supertracks is a leading technology company dedicated to providing innovative and high-quality solutions to our clients. We are looking for an experienced Project Manager to join our dynamic team.

**Key Responsibilities:**
- Create and design engaging game mechanics and storylines.
- Develop game concepts and prototypes.
- Collaborate with artists and developers to bring game ideas to life.
- Balance game difficulty and progression.
- Conduct playtests and gather feedback for improvements.

**Qualifications:**
- Bachelor’s degree in Game Design, Computer Science, or related field.
- Minimum of 3 years of experience in game design.
- Proficiency with game development tools and software (e.g., Unity, Unreal Engine).
- Strong creative and storytelling skills.
- Excellent problem-solving and analytical skills.
`,

  'Senior Game Developer': `**Position Title:** Senior Game Developer

**Location:** Austin, USA

**Job Type:** [Full-Time/Part-Time/Contract]

**About Us:**
Supertracks is a leading technology company dedicated to providing innovative and high-quality solutions to our clients. We are looking for an experienced Project Manager to join our dynamic team.

**Key Responsibilities:**
- Lead the development of high-quality games for various platforms.
- Provide technical guidance and mentorship to junior developers.
- Optimize game performance and resolve technical issues.
- Collaborate with designers and artists to implement game features.
- Stay updated on industry trends and emerging technologies.

**Qualifications:**
- Bachelor’s degree in Computer Science, Game Development, or related field.
- Minimum of 5 years of experience in game development.
- Proficiency in programming languages such as C++, C#, or Java.
- Experience with game engines (e.g., Unity, Unreal Engine).
- Strong problem-solving and analytical skills.
`,

  'Senior Software Engineer': `**Position Title:** Senior Software Engineer

**Location:** New York, USA

**Job Type:** [Full-Time/Part-Time/Contract]

**About Us:**
Supertracks is a leading technology company dedicated to providing innovative and high-quality solutions to our clients. We are looking for an experienced Project Manager to join our dynamic team.

**Key Responsibilities:**
- Develop and maintain advanced software applications.
- Provide technical guidance and mentorship to junior engineers.
- Conduct thorough code reviews to ensure code quality.
- Identify and address performance bottlenecks.
- Collaborate with cross-functional teams to define and design new features.

**Qualifications:**
- Bachelor’s or Master’s degree in Computer Science, Software Engineering, or related field.
- Minimum of 5 years of experience in software development.
- Proficiency in multiple programming languages (e.g., Java, Python, C#).
- Experience with web development frameworks (e.g., React, Angular, Vue).
- Strong problem-solving and analytical skills.
`,

  'Senior Project Manager': `**Position Title:** Senior Project Manager

**Location:** Seattle, USA

**Job Type:** [Full-Time/Part-Time/Contract]

**About Us:**
Supertracks is a leading technology company dedicated to providing innovative and high-quality solutions to our clients. We are looking for an experienced Project Manager to join our dynamic team.

**Key Responsibilities:**
- Manage large-scale projects and ensure successful delivery.
- Oversee project timelines, deliverables, and team coordination.
- Communicate project status to stakeholders.
- Identify and mitigate project risks.
- Ensure projects are delivered on time and within scope.

**Qualifications:**
- Bachelor’s degree in Business, Engineering, or related field.
- Minimum of 7 years of experience in project management.
- PMP or equivalent certification is a plus.
- Strong leadership and communication skills.
- Experience with project management software (e.g., MS Project, Asana).
`,
};

const validationSchema = Yup.object({
  fullName: Yup.string().required('Full Name is required'),
  email: Yup.string().email('Invalid email address').required('Email is required'),
  phone: Yup.string().required('Phone number is required'),
  location: Yup.string().required('Location is required'),
  jobTitle: Yup.string().required('Job Title is required'),
  resume: Yup.mixed().required('Resume is required'),
});

const CareersPage = () => {
  const [selectedCountry, setSelectedCountry] = useState('India');
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedJob, setSelectedJob] = useState('');
  const [selectedJobDescription, setSelectedJobDescription] = useState('');
  const formRef = React.useRef();

  const formik = useFormik({
    initialValues: {
      fullName: '',
      email: '',
      phone: '',
      location: '',
      jobTitle: '',
      resume: null,
    },
    validationSchema: validationSchema,
    onSubmit: (values, { setSubmitting, resetForm }) => {
        emailjs.sendForm('service_4747w2j', 'template_77oe94s', formRef.current, '24h2zM6cmq-PmhLP1')
        .then((response) => {
          console.log('SUCCESS!', response.status, response.text);
          alert('Application submitted successfully');
          resetForm();
        })
        .catch((error) => {
          console.log('FAILED...', error);
          alert('Failed to submit application');
        })
        .finally(() => {
          setSubmitting(false);
        });
    },
  });

  const handleJobSelect = (job) => {
    setSelectedJob(job.title);
    setSelectedJobDescription(jobDescriptions[job.title] || 'Job description not available.');
    formik.setFieldValue('jobTitle', job.title);
  };

  const filteredJobs = jobListings[selectedCountry].filter((job) =>
    job.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <Container>
      {/* Header Section */}
      <Box sx={{ py: 8, textAlign: 'center' }}>
        <Typography variant="h3" component="h1" gutterBottom>
          Careers at Supertracks
        </Typography>
        <Typography variant="h5" component="p" gutterBottom>
          Join our team and help us build the future of technology.
        </Typography>
      </Box>

      {/* Filters Section */}
      <Box sx={{ py: 4, display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexDirection: { xs: 'column', sm: 'row' } }}>
        <TextField
          select
          label="Select Region"
          value={selectedCountry}
          onChange={(e) => setSelectedCountry(e.target.value)}
          sx={{ width: { xs: '100%', sm: '200px' }, mb: { xs: 2, sm: 0 } }}
        >
          {Object.keys(jobListings).map((country) => (
            <MenuItem key={country} value={country}>
              {country}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          label="Search Job Titles"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton>
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
          sx={{ width: { xs: '100%', sm: '300px' } }}
        />
      </Box>

      {/* Job Listings Section */}
      <Box sx={{ py: 8 }}>
        <Typography variant="h4" component="h2" gutterBottom>
          Job Openings
        </Typography>
        <Grid container spacing={4}>
          {filteredJobs.map((job, index) => (
            <Grid item xs={12} md={6} key={index}>
              <Card sx={{ height: '100%' }}>
                <CardContent>
                  <Typography variant="h6" component="h4" gutterBottom>
                    {job.title}
                  </Typography>
                  <Typography variant="body1" component="p">
                    Location: {job.location}
                  </Typography>
                  <Typography variant="body2" component="p" sx={{ wordWrap: 'break-word' }}>
                    {job.description}
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{ mt: 2 }}
                    onClick={() => handleJobSelect(job)}
                  >
                    Apply Now
                  </Button>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>

      {/* Application Form Section */}
      {selectedJob && (
        <Box sx={{ py: 8, backgroundColor: '#f0f0f0', textAlign: 'center' }}>
          <Typography variant="h4" component="h2" gutterBottom>
            Apply for {selectedJob}
          </Typography>
          <form ref={formRef} onSubmit={formik.handleSubmit} encType="multipart/form-data">
            <Grid container spacing={4} justifyContent="center">
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  id="fullName"
                  name="fullName"
                  label="Full Name"
                  value={formik.values.fullName}
                  onChange={formik.handleChange}
                  error={formik.touched.fullName && Boolean(formik.errors.fullName)}
                  helperText={formik.touched.fullName && formik.errors.fullName}
                  sx={{ mb: 2 }}
                />
                <TextField
                  fullWidth
                  id="email"
                  name="email"
                  label="Email"
                  type="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                  sx={{ mb: 2 }}
                />
                <TextField
                  fullWidth
                  id="phone"
                  name="phone"
                  label="Phone Number"
                  value={formik.values.phone}
                  onChange={formik.handleChange}
                  error={formik.touched.phone && Boolean(formik.errors.phone)}
                  helperText={formik.touched.phone && formik.errors.phone}
                  sx={{ mb: 2 }}
                />
                <TextField
                  fullWidth
                  id="location"
                  name="location"
                  label="Location"
                  value={formik.values.location}
                  onChange={formik.handleChange}
                  error={formik.touched.location && Boolean(formik.errors.location)}
                  helperText={formik.touched.location && formik.errors.location}
                  sx={{ mb: 2 }}
                />
                <TextField
                  fullWidth
                  id="jobTitle"
                  name="jobTitle"
                  label="Job Title"
                  value={formik.values.jobTitle}
                  onChange={formik.handleChange}
                  error={formik.touched.jobTitle && Boolean(formik.errors.jobTitle)}
                  helperText={formik.touched.jobTitle && formik.errors.jobTitle}
                  sx={{ mb: 2 }}
                />
                <Button
                  variant="contained"
                  component="label"
                  fullWidth
                  sx={{ mb: 2 }}
                >
                  Upload Resume
                  <input
                    type="file"
                    hidden
                    id="resume"
                    name="resume"
                    onChange={(event) => {
                      formik.setFieldValue('resume', event.currentTarget.files[0]);
                    }}
                  />
                </Button>
                {formik.touched.resume && formik.errors.resume && (
                  <Typography variant="body2" color="error">
                    {formik.errors.resume}
                  </Typography>
                )}
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  type="submit"
                  disabled={formik.isSubmitting}
                >
                  Submit Application
                </Button>
              </Grid>
              {selectedJobDescription && (
                <Grid item xs={12} md={6}>
                  <Box sx={{ textAlign: 'left', wordWrap: 'break-word' }}>
                    <Typography variant="h5" component="h3" gutterBottom>
                      Job Description
                    </Typography>
                    <Typography variant="body1" component="div">
                      <pre style={{ whiteSpace: 'pre-wrap' }}>{selectedJobDescription}</pre>
                    </Typography>
                  </Box>
                </Grid>
              )}
            </Grid>
          </form>
        </Box>
      )}
    </Container>
  );
};

export default CareersPage;






