import React from 'react';
import { Box, Typography, Button } from '@mui/material';

const Banner = ({ image, title, description, buttonText, buttonLink, isSmallScreen }) => {
  return (
    <Box
      sx={{
        backgroundImage: `url(${image})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: isSmallScreen ? '300px' : '400px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: 'white',
        textShadow: '0px 0px 10px rgba(0,0,0,0.5)',
        borderRadius: '10px',
        my: 4,
        marginTop: '100px',
        px: isSmallScreen ? 2 : 3,
        py: isSmallScreen ? 2 : 3,
      }}
    >
      <Box sx={{ textAlign: 'center', p: isSmallScreen ? 1 : 3 }}>
        <Typography className="font-size24" variant={isSmallScreen ? "h5" : "h3"} component="h1" gutterBottom>
          {title}
        </Typography>
        <Typography className="font-size16 mt50" variant={isSmallScreen ? "body2" : "h6"} component="p" gutterBottom>
          {description}
        </Typography>
        <Button className="font Buttons font-size16 mt50" variant="contained" color="primary" href={buttonLink}>
          {buttonText}
        </Button>
      </Box>
    </Box>
  );
};

export default Banner;
